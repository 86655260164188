/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import {Uuid} from "../../../util/uuid";

const MODULE_NAME = "site";

angular.module(MODULE_NAME, [])

  .factory("ConsentStatistic", function(adnResource) {
    return adnResource('consentstatistic', {}, {}, 'ConsentStatistic');
  })

  .factory("SiteCountry", function(adnResource) {
    return adnResource('sites/availablecountries', {}, {}, 'SiteCountry');
  })

  .factory("Site", function(adnResource) {
    const Site = adnResource('sites', {summaryType: '@summaryType'});

    Site.create = function(defaults) {
      return new Site(_.merge({
        id: Uuid.generate(),
      }, defaults));
    };

    return Site;
  });

export default MODULE_NAME;