/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import _ from 'lodash';

import resources from '../../../components/api/resources/resources';
import adnInactiveTargets from "../../../components/util/adn-inactive-targets";

const MODULE_NAME = 'inactive-targets';

angular.module(MODULE_NAME, [
  resources,
  uiSelect,
  uiBootstrap,
  uiTranslate,
  adnInactiveTargets
])

  .component('adnInactiveTargets', {
    template: `
      <div>
        <adn-promise-spinner resolved="ctrl.scanCheck" and-hide="true">
          <div ng-if="ctrl.creatives.length > 0 || ctrl.lineItems.length > 0">
            <div class="alert alert-info">
              <div ng-if="ctrl.lineItems.length > 0">
                <div ng-if="!ctrl.singular">{{ctrl.lineItems.length}} line items target inactive segments or locations that can no longer be matched with.</div>
                <div ng-if="ctrl.singular">This line item targets inactive segments or locations that can no longer be matched with.</div>
              </div>
              <div ng-if="ctrl.creatives.length > 0">
                <div ng-if="!ctrl.singular">{{ctrl.creatives.length}} creatives target inactive segments or locations that can no longer be matched with.</div>
                <div ng-if="ctrl.singular">This creative targets inactive segments or locations that can no longer be matched with.</div>
              </div>
            </div>
            <div class="topStatsWidgetContainer" style="margin-bottom: 15px; margin-top: -10px;">
              <p class="small"><a ng-click="ctrl.showFullDetails=!ctrl.showFullDetails"><span class="fa fa-chevron-right" style="font-size: 7px;" ng-class="{'fa-rotate-90': ctrl.showFullDetails}"></span> <span ng-if="ctrl.showFullDetails">hide</span><span ng-if="!ctrl.showFullDetails">show</span> full details</a></p>
              <div ng-if="ctrl.showFullDetails" class="topStatsTableContainer">
                <div class="list-group">
                  <table class="table table-condensed table-bordered table-hover" ng-if="ctrl.lineItems.length > 0">
                    <tr><th>Line Item</th><th>Inactive Targeting</th></tr>
                    <tr ng-repeat="lineItem in ctrl.lineItems">
                      <td><a ui-sref="app.line-items.line-item({id: lineItem.lineItem.id})">{{lineItem.lineItem.name}}</a></td>
                      <td>
                        <ul ng-if="lineItem.namedLocations.length > 0 || lineItem.segments.length > 0" style="margin: 0 0 0 -26px" ng-class="{'doubleColumned': (lineItem.namedLocations.length + lineItem.segments.length) > 1}">
                          <li ng-repeat="nl in lineItem.namedLocations"><strong>Location:</strong> {{nl.name}}</li>
                          <li ng-repeat="u in lineItem.segments"><strong>User Segment:</strong> {{u.name}}</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                  <table class="table table-condensed table-bordered table-hover" ng-if="ctrl.creatives.length > 0">
                    <tr><th>Line Item</th><th>Inactive Targeting</th></tr>
                    <tr ng-repeat="creative in ctrl.creatives">
                      <td><a ui-sref="app.creatives.creative({id: creative.creative.id})">{{creative.creative.name}}</a></td>
                      <td>
                        <ul ng-if="creative.namedLocations.length > 0 || creative.segments.length > 0" style="margin: 0 0 0 -26px" ng-class="{'doubleColumned': (creative.namedLocations.length + creative.segments.length) > 1}">
                          <li ng-repeat="nl in creative.namedLocations"><strong>Location:</strong> {{nl.name}}</li>
                          <li ng-repeat="u in creative.segments"><strong>User Segment:</strong> {{u.name}}</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </adn-promise-spinner>
      </div>
    `,
    controllerAs: 'ctrl',
    bindings: {
      refresh: '<',
      limit: '<',
      singular: '<'
    },
    controller: function($timeout, adnInactiveTargets) {
      const ctrl = this;

      ctrl.$onInit = function() {
        const limitIds = _.map(ctrl.limit, 'id');

        ctrl.scanCheck = false;
        adnInactiveTargets.results(ctrl.refresh).then(function(data) {
          ctrl.creatives = _.filter(data.creatives, function(c) {
            return ctrl.singular ? limitIds.indexOf(_.get(c, ['creative', 'id'])) > -1 : c;
          });
          ctrl.lineItems = _.filter(data.lineItems, function(li) {
            return ctrl.singular ? limitIds.indexOf(_.get(li, ['lineItem', 'id'])) > -1 : li;
          });
          ctrl.scanCheck = true;
        });
      };
    }
  });

export default MODULE_NAME;