/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';

import {ASSET_ERROR} from "../api/resources/resources-constants";
import {ApiConfig} from "../api/api";

const MODULE_NAME = "asset-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('adnAssetHelper', function($q, $http, Upload) {
    function theUpload(uri, parentId, data, assetId) {
      if (!data || !(data.file || data.content)) {
        return $q.reject(ASSET_ERROR.NO_FILE);
      }
      let pathPortions = _.isArray(uri) ? uri : [uri];
      if (parentId) {
        pathPortions.push(parentId);
      }
      if (assetId) {
        pathPortions.push(assetId);
      }
      if (data.transcodeConfig) {
        data.transcodeConfig = JSON.stringify(data.transcodeConfig);
      }
      return Upload.upload({
        url: ApiConfig.obtain().getVersionedUri(pathPortions),
        data: data
      }).then(function(response) {
        if (_.isEmpty(response.data)) {
          return $q.reject(ASSET_ERROR.EMPTY_RESPONSE);
        }
        // always return an array so we don't have to muck around with type detection
        return angular.isArray(response.data) ? response.data : [response.data];
      });
    }


    return {
      getAsJson: function(uriParams, qStringParams) {
        return $http.get(ApiConfig.obtain().getVersionedUri(uriParams), {
          params: _.assign({}, qStringParams, {format: 'json'})
        }).then(_.iteratee('data'));
      },
      fromParentAsJson: function(path, id, qStringParams) {
        return $http.get(ApiConfig.obtain().getVersionedUri([path, id]), {
          params: _.assign({}, qStringParams, {format: 'json', concise: true, pageSize: 1000})
        }).then(_.iteratee('data'));
      },
      helpfulUpload: function(uri, parentId, data) {
        return theUpload(uri, parentId, data, null);
      },
      upload: function(uri, parentId, data, assetId) {
        return theUpload(uri, parentId, data, assetId);
      }
    };
  });

export default MODULE_NAME;