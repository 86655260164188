/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import {AUTH_EVENT} from "../auth/auth-constants";

import auth from '../auth/auth';
import localNetworkProfile from '../session/local-network-profile';
import workspaceRegistry from '../workspace-registry';
import {LocalNetworkInfo} from "../session/local-network-info";

const MODULE_NAME = "network-helper";

angular.module(MODULE_NAME, [uiRouter, auth, localNetworkProfile, workspaceRegistry])

  .factory('adnNetworkHelper', function($window, $state, $q, $urlRouter, WorkspaceRegistry, LocalNetworkProfile, NetworkInfo, localUserProfile) {

    return {
      switchNetwork: function(baseCtrl, $rootScope, network) {
        localUserProfile.set('defaultNetworkTagId', network.tagId);
        LocalNetworkProfile.set('networkId', network.id);
        LocalNetworkProfile.set('tagId', network.tagId);

        const nInfoProm = NetworkInfo.get(function(data) {
          LocalNetworkInfo.obtain().setInfo(data);
        }).$promise;

        $q.all([LocalNetworkProfile.get().refresh(), WorkspaceRegistry.sync(), nInfoProm]).then(function() {
          if ($state.includes('app.errors')) {
            baseCtrl.currentNetwork = network;
            $urlRouter.sync();
            $window.location.reload();
          } else {
            baseCtrl.currentNetwork = network;
            $state.reload().catch(function() {
              // don't transition to an error state on network switch
              return $state.go('app.home', null, {inherit: false, reload: true});
            }).finally(function() {
              return $window.location.reload();
            });
          }

          $rootScope.$broadcast(AUTH_EVENT.networkChange, network);
        });
      }
    };
  });

export default MODULE_NAME;