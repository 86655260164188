import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import ngSanitize from 'angular-sanitize';
import uiSelect from 'ui-select';

import resources from '../../components/api/resources/resources';
import submitDirective from './directives/submit-directive';
import formButtonsComp from './directives/form-controls/form-buttons-component';
import soloVisibilityInfoComp from './directives/solo-visibility-info-component';
import deleteInfoComp from './directives/delete-info-component';
import searchListComp from './directives/search-list-component';
import notesComp from './directives/notes/notes-component';
import listHelper from './controller/list-helper';
import creativeDims from '../common/controller/creative-dims-output';
import scannedCreatives from '../common/controller/scanned-creatives';
import inactiveTargeting from '../common/controller/inactive-targeting';
import labelsDirective from './directives/form-controls/labels-component';
import dateSelector from './directives/form-controls/date-selector';
import moneyDirective from './directives/form-controls/money-directive';
import currencyDirective from './directives/form-controls/currency-directive';
import moneySpacerDirective from './directives/form-controls/money-spacer-directive';
import localUserPermissions from '../../components/session/local-user-permissions';
import localNetworkProfile from '../../components/session/local-network-profile';
import httpPrefixer from '../common/directives/form-controls/http-prefixer-directive';
import httpsPrefixer from '../common/directives/form-controls/https-prefixer-directive';
import optionsComponent from './directives/delete-component';

const MODULE_NAME = "standard-form-module";

angular.module(MODULE_NAME, [
  translate,
  uiBootstrap,
  ngSanitize,
  uiSelect,
  notesComp,
  resources,
  submitDirective,
  formButtonsComp,
  soloVisibilityInfoComp,
  scannedCreatives,
  inactiveTargeting,
  deleteInfoComp,
  searchListComp,
  labelsDirective,
  listHelper,
  creativeDims,
  dateSelector,
  moneyDirective,
  currencyDirective,
  moneySpacerDirective,
  localNetworkProfile,
  httpPrefixer,
  httpsPrefixer,
  optionsComponent,
  localUserPermissions
]);

export default MODULE_NAME;