/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import translate from 'angular-translate';

import resources from '../../../components/api/resources/resources';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';
import lineChartDirective from './line-directive';

import {Downloader} from "../../../components/util/downloader";
import {RELATED_CHART_DATA} from "./related-data-chart-config";

import template from './related-data-chart.html';
import {ADN_CHART_COUNT_TYPES, ADN_CHART_STAT_TYPES} from "../options/chart-options";
import topDomainTableComponent from '../../common/controller/top-domain-table-component';

const MODULE_NAME = "related-data-chart-component";

angular.module(MODULE_NAME, [
  translate,
  resources,
  topDomainTableComponent,
  promiseSpinnerComponent,
  lineChartDirective
])

  .component('adnRelatedDataChart', {
    template: template,
    bindings: {
      obj: '<',
      objName: '<',
      key: '@',
      chartType: '@',
      dateSelectorId: '@',
      relatedDataType: '@'
    },
    controllerAs: 'ctrl',
    controller: function(statsResource, $timeout, $log, $translate, $document, $window, $templateCache, UserProfile, ChartOptionsUtil, localUserProfile) {
      const ctrl = this;
      ctrl.pieError = null;
      let params = {};

      ctrl.$onInit = function() {
        ctrl.relatedChartData = RELATED_CHART_DATA[ctrl.relatedDataType] ? RELATED_CHART_DATA[ctrl.relatedDataType] : RELATED_CHART_DATA['TRAFFIC'];
        $templateCache.put("relatedChartsData.html", ctrl.relatedChartData.template);

        let chartOptions = ChartOptionsUtil.getOptions(ctrl.chartType),
          localUser = localUserProfile.get(),
          profile = localUser.profile,
          profileStartDate = _.get(profile[chartOptions.perfChartOptions], 'startDate'),
          profileEndDate = _.get(profile[chartOptions.perfChartOptions], 'endDate'),
          profileCountType = ADN_CHART_COUNT_TYPES[_.get(profile[chartOptions.perfChartOptions], 'countTypeId')],
          profileStatType = ADN_CHART_STAT_TYPES[_.get(profile[chartOptions.perfChartOptions], 'statTypeId')];

        ctrl.dateOption = ChartOptionsUtil.getDateOptions()[_.get(profile[chartOptions.perfChartOptions], 'dateOptionId')];
        if (!ctrl.dateOption && (!profileStartDate || !profileEndDate)) {
          ctrl.dateOption = ChartOptionsUtil.getDateOptions().last12Hours;
        }
        params.startDate = ctrl.dateOption ? ctrl.dateOption.startDate() : moment(profileStartDate);
        params.endDate = ctrl.dateOption ? ctrl.dateOption.endDate() : moment(profileEndDate);
        ctrl.countType = profileCountType ? profileCountType : ADN_CHART_COUNT_TYPES.singular;
        ctrl.statType = profileStatType ? profileStatType : ADN_CHART_STAT_TYPES.impression;

        params[ctrl.key] = ctrl.obj.id;

        ctrl.allStats = {};

        ctrl.isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";

        ctrl.changeHook = {
          chartChangeHandlers: [],
          getTotals: () => {
            return ctrl.totals;
          }
        };

        let callForStats = function() {
          params.groupBy = ChartOptionsUtil.getChunkSize(params.startDate, params.endDate, null, true).key;
          params.statTypeId = ctrl.statType.id;

          statsResource[ctrl.relatedChartData.statsMethod](params, function(data) {
            ctrl.allStats.chunks = data.chunks;
            ctrl.allStats.chunkRange = data.chunkRange;
            ctrl.allStats.totals = data.totals;
            ctrl.totals = data.totals;

            ctrl.osEmpty = _.isEmpty(data.totals.osType);
            ctrl.mobileBrandEmpty = _.isEmpty(data.totals.mobileBrand);
            ctrl.locationsEmpty = _.isEmpty(data.totals.country);
            ctrl.domainsEmpty = _.isEmpty(data.totals.domain);
            ctrl.appEmpty = _.isEmpty(data.totals.app);

            _.forEach(ctrl.relatedChartData.dataTypes, function(dataType) {
              if (dataType.eventHook.redraw) {
                dataType.eventHook.redraw();
              }
            });

            _.forEach(ctrl.changeHook.chartChangeHandlers, function(f) {
              f(ctrl.totals);
            });
          }, function(err) {
            ctrl.pieError = err;
            $log.warn(err);
          }, ctrl);
        };
        callForStats();

        ctrl.changeCallback = function(dateOptionId, startDate, endDate, countTypeId, statTypeId) {
          ctrl.dateOption = ChartOptionsUtil.getDateOptions()[dateOptionId];
          params.startDate = moment(startDate);
          params.endDate = moment(endDate);
          ctrl.countType = ADN_CHART_COUNT_TYPES[countTypeId];
          ctrl.statType = ADN_CHART_STAT_TYPES[statTypeId];
          callForStats();

          profile[chartOptions.perfChartOptions] = _.assign({}, profile[chartOptions.perfChartOptions], {
            dateOptionId: _.get(ctrl.dateOption, 'id'),
            startDate: params.startDate.toDate(),
            endDate: params.endDate.toDate(),
            countTypeId: ctrl.countType.id,
            statTypeId: ctrl.statType.id
          });

          UserProfile.save({
            id: localUser.userId,
            profile: _.pick(profile, chartOptions.perfChartOptions)
          }, function(apiUser) {
            localUser = localUserProfile.safeSaveProfile(chartOptions.perfChartOptions, apiUser.profile[chartOptions.perfChartOptions]);
            profile = localUser.profile;
          });
        };

        ctrl.save = function() {

          let refData = _.keyBy(_.map(ctrl.allStats.chunks, function(chunk) {
            return chunk;
          }), 'chunkStart');

          let dataFields = [{name: 'Date', id: 'chunkStart'}];
          _.forEach(ctrl.relatedChartData.dataTypes, function(dataType) {
            dataFields = dataFields.concat(_.map(ctrl.allStats.totals[dataType.id], function(d, k) {
              return {
                name: dataType.keyed ? $translate.instant(dataType.keyed + "." + k) || k : k,
                id: k,
                groupId: dataType.id
              };
            }));
          });
          let headingFields = _.map(dataFields, 'name');
          let fields = _.map(dataFields, 'id');
          let rows = [];

          _.forEach(ctrl.allStats.chunkRange, function(chunkStart) {
            let rowData = {};
            _.forEach(dataFields, function(field) {
              if (field.id === 'chunkStart') {
                rowData[field.id] = chunkStart;
              } else {
                let refDatum = refData[chunkStart];
                if (refDatum) {
                  rowData[field.id] = _.get(refDatum, [field.groupId, field.id]) || 0;
                } else {
                  rowData[field.id] = 0;
                }
              }
            });
            rows.push(rowData);
          });

          let date = ctrl.dateOption ? $translate.instant('chart.dateOption.' + ctrl.dateOption.id) : "custom dates";
          let defaultName = (ctrl.obj.name + " " + ctrl.relatedChartData.csvTitle + " " + date + ".xls").replace(/ /g, "-");
          new Downloader().saveXls2(defaultName, headingFields, fields, rows, []);
        };
      };
    }
  });

export default MODULE_NAME;