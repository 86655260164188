import * as _ from "lodash";

export class ModelComms {
  private subs = [];
  private currentModel: any;
  private currentType: string;
  private currentRelData: any;
  private isNew = false;

  addSubscriber(subFunc, blockImmediateCallback) {
    this.subs.push(subFunc);

    if (!blockImmediateCallback && this.currentModel) {
      subFunc(_.cloneDeep(this.currentModel), null, this.isNew);
    }
    if (!blockImmediateCallback && this.currentRelData) {
      subFunc(_.cloneDeep(this.currentRelData), this.currentType, this.isNew);
    }
  }

  relDataUpdated(relData, type: string) {
    this.currentRelData = relData;
    this.currentType = type || "undefinedType";
    _.forEach(this.subs, (s) => {
      s(_.cloneDeep(this.currentRelData), type, this.isNew);
    });
  }

  modelUpdated(model, isNew) {
    this.currentModel = model;
    this.isNew = isNew;
    _.forEach(this.subs, (s) => {
      s(_.cloneDeep(this.currentModel), null, this.isNew);
    });
  }

  commMade(data) {
    _.forEach(this.subs, (s) => {
      s(_.cloneDeep(data), "commMade");
    });
  }
}