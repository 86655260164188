/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import toaster from 'angular-toastr';
import translate from 'angular-translate';

import auth from '../../../../components/auth/auth';
import {ApiConfig} from "../../../../components/api/api";

const MODULE_NAME = 'api-error-handler';

angular.module(MODULE_NAME, [
  translate,
  toaster,
  auth
])

  .config(function($httpProvider) {
    $httpProvider.interceptors.push('ApiErrorHandler');
  })

  .factory('ApiErrorHandler', function($q, $log, $injector, $translate, $location, AuthService) {
    let toastr, $state,
      ignoreErrors = {"400": 400, "401": 401, "404": 404, "422": 422},
      ignorePaths = ['/version-copy'],
      ignore404Urls = ["/stats", "uiTest=true", '/synchronous/visitor', 'consentstatistic', 'publicreleasenotes', 'releasenotes'],
      counter = 0,
      noConnectionTriggered = false;

    return {
      response: function(response) {
        if (_.get(response, ['status']) === 200 && _.get(response, ['config', 'url']).indexOf("/api/") > -1) {
          counter = 0;
          if (noConnectionTriggered) {
            noConnectionTriggered = false;
          }
        }
        return response;
      },
      responseError: function(response) {
        if (_.isUndefined(response.status)) {
          $log.warn("Seem to have found an unspecified error.", response);
          return;
        }
        if (response.status === 404 && !_.get(response, ['config', 'ignore404']) && AuthService.isAuthenticated() && !AuthService.getSession().isExpired()) {
          $state = $state || $injector.get("$state");

          let hasIgnore404Url = !!_.find(ignore404Urls, function(igUrl) {
            return response.config.url.indexOf(igUrl) > 0 || _.get(response.config, ['params', 'uiTest']) === true || _.get(response.config, 'method') === 'HEAD';
          });
          let hasIgnore404LocationUrl = !!_.find(ignorePaths, function(igUrl) {
            return ($location.path() || "").indexOf(igUrl) > -1;
          });
          if (!hasIgnore404Url && !hasIgnore404LocationUrl) {
            $state.go('app.errors.api404', {rejection: response}, {
              location: false,
              inherit: false
            });
          }
        } else if (!ignoreErrors[response.status] && !_.get(response.config, 'hasErrorHandler')) {
          if (ApiConfig.obtain().getId() === 'localhost') {
            if (_.get(response.config, 'url', '').indexOf("preview") > -1) {
              // just avoiding the annoying toastr alerts when in dev mode without the ad server up and running.
              return $q.reject(response);
            }
          }

          toastr = toastr || $injector.get("toastr");
          if (response.status === 0) {
            toastr.warning($translate.instant('errors.api.noConnection'), $translate.instant('errors.api.noConnection.title'));
            noConnectionTriggered = true;
          } else if (response.status === 403) {
            toastr.error($translate.instant('errors.api.unauthorised'), $translate.instant('errors.api.unauthorised.title'));
          } else if (response.status === -1) {
              counter++;
              if (counter >= 3) {
                toastr.warning($translate.instant('errors.api.noConnection'), $translate.instant('errors.api.noConnection.title'));
                noConnectionTriggered = true;
              }
          } else {
            toastr.warning($translate.instant('errors.api.generic'), $translate.instant('errors.api.title'));
          }
        }
        return $q.reject(response);
      }
    };
  });

export default MODULE_NAME;